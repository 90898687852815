import { useEffect, useRef, useState } from "react"
import { withPrevent } from "./utils"

export default function InputField({ label, value, list, onChange }) {
  const [showList, setShowList] = useState(false)
  const autocompleteRef = useRef()

  const autocompleteList = list
    ? list.filter(
        g => g.toLowerCase().indexOf((value || "").toLowerCase()) > -1
      )
    : []

  useEffect(() => {
    function clickHandler(e) {
      if (!autocompleteRef.current.contains(e.target)) {
        setShowList(false)
      }
    }
    if (autocompleteRef.current) {
      document.addEventListener("mousedown", clickHandler, false)

      return () => {
        document.removeEventListener("mousedown", clickHandler, false)
      }
    }
  }, [autocompleteRef])

  function setItem(item) {
    onChange(item)
    setShowList(false)
  }

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div
        ref={autocompleteRef}
        className={showList && autocompleteList.length > 0 ? "is-active control dropdown" : "control dropdown"}
      >
        <input
          value={value}
          onChange={e => onChange(e.target.value)}
          className="input"
          type="text"
          placeholder={label}
          onFocus={() => setShowList(true)}
        />
        {list && (
          <div className="dropdown-menu">
            <div className="dropdown-content">
              {autocompleteList.map(item => (
                <a
                  href="#setGroup"
                  className="dropdown-item"
                  key={item}
                  onClick={withPrevent(() => setItem(item))}
                >
                  {item}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

import Button from "./Button"
import colors from "./colors"

export default function NavBar({ title, showBack, isPrimary, isFixedTop, children, onBack }) {
  return (
    <div className={`navbar ${isPrimary ? colors.primary : ""} ${isFixedTop ? "is-fixed-top" : ""}`}>
      <div className="navbar-brand">
        {showBack && (
          <div className="navbar-item">
            <Button icon="arrow_back" onClick={onBack} />
          </div>
        )}
        <div className="navbar-item">
          <div className={`title is-4  ${isPrimary ? "has-text-white" : ""}`}>
            {title}
          </div>
        </div>
      </div>
      <div className="navbar-menu">
        <div className="navbar-end">{children}</div>
      </div>
    </div>
  )
}

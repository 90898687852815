import { useEffect, useState } from "react"
import styled from "styled-components"
import { register } from "./serviceWorkerRegistration"

const NotificationContainer = styled.div.attrs(props => ({
  className: "notification is-info is-light"
}))`
  position: fixed;
  bottom: 0;
  width: 100%;
`

export default function UpdateNotification() {
  const [isUpdate, setIsUpdate] = useState(false)

  function update() {
    window.location.reload()
  }

  useEffect(() => {
    register({
      onUpdate: reg => {
        reg.waiting.postMessage({ type: "SKIP_WAITING" })
        setIsUpdate(true)
      }
    })
  }, [])

  return isUpdate ? (
    <NotificationContainer>
      <p>Dostępna jest nowa wersja aplikacji.</p>
      <div className="has-text-right">
        <button onClick={update} className="button is-small is-success">
          Uruchom ponownie
        </button>
      </div>
    </NotificationContainer>
  ) : null
}

import styled from "styled-components"
import { withPrevent } from "./utils"

const Card = styled.div.attrs(props => ({
  className: "card",
}))`
  .card-footer-item {
    border: none;
  }

  a.card-footer-item {
    flex-grow: inherit;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .card-footer {
    border: none;
  }

  .card-header {
    box-shadow: none;
  }
`

export default function ModalYesNo({ title, onOK, onCancel, children }) {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <Card>
          <header className="card-header">
            <p className="card-header-title">{title}</p>
          </header>
          <div className="card-content">
            <div className="content">{children}</div>
          </div>
          <footer className="card-footer">
            <div className="card-footer-item"></div>
            <a href="#yes" onClick={withPrevent(onOK)} className="card-footer-item">
              Tak
            </a>
            <a href="#no" onClick={withPrevent(onCancel)} className="card-footer-item">
              Nie
            </a>
          </footer>
        </Card>
      </div>
    </div>
  )
}

import styled from "styled-components"
import GroupBox from "./GroupBox"
import AddBox from "./AddBox"
import EditItem from "./EditItem"
import ListContainer from "./ListContainer"
import { toggleItem, useGroupedLists } from "./database"
import { useState } from "react"

const ShoppingListContainer = styled.div`
  padding-bottom: 70px;
`

export default function ShoppingList({ batchGroup, onBatchGroupClose }) {
  const [editItem, setEditItem] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const { groupedLists,groupNames, serverError } = useGroupedLists()

  function edit(item) {
    setEditItem(item)
  }

  function selectItem(item, selected) {
    if (selected) {
      setSelectedItems([...selectedItems, item])
    } else {
      setSelectedItems(selectedItems.filter(x => x !== item))
    }
  }

  return (
    <div>
      {!serverError ? (
        <ShoppingListContainer>
          <ListContainer
            items={groupedLists["default"]}
            onToggleItem={toggleItem}
            onEditItem={edit}
            selectMode={batchGroup}
            onSelectItem={selectItem}
          />
          {groupNames.map(g => (
            <ListContainer
              key={g}
              title={g}
              items={groupedLists[g]}
              onToggleItem={toggleItem}
              onEditItem={edit}
              onSelectItem={selectItem}
              selectMode={batchGroup}
            />
          ))}
        </ShoppingListContainer>
      ) : (
        <h3 className="title">Brak danych. Serwer działa?</h3>
      )}
      {batchGroup ? (
        <GroupBox items={selectedItems} onClose={onBatchGroupClose} />
      ) : (
        <AddBox />
      )}
      {editItem && (
        <EditItem item={editItem} onClose={() => setEditItem(null)} />
      )}
    </div>
  )
}

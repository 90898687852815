import NavBar from "./NavBar"
import NavBarMore from "./NavBarMore"
import ModalYesNo from "./ModalYesNo"
import HistoryDialog from "./HistoryDialog"
import ShoppingList from "./ShoppingList"
import Button from "./Button"
import { useState } from "react"
import Icon from "./Icon"
import { withPrevent } from "./utils"
import styled from "styled-components"
import { removeCompleted } from "./database"
import UpdateNotification from "./UpdateNotification"

const MainSection = styled.div`
  .container {
    max-width: 500px;
  }

  &.section {
    padding: 8px;
  }
`

function App() {
  const [batchGroup, setBatchGroup] = useState(false)
  const [historyOpen, setHistoryOpen] = useState(false)
  const [deletePrompt, setDeletePrompt] = useState(false)

  async function logout() {
    const resp = await fetch("/logout", {
      method: "POST"
    })

    if (resp.ok) {
      window.location.href = "/logout"
    }
  }

  async function removeCompletedItems() {
    await removeCompleted()
    setDeletePrompt(false)
  }

  return (
    <div className="is-unselectable">
      <NavBar title="Zakupy" isFixedTop isPrimary>
        <div className="navbar-item">
          <Button
            isPrimary
            icon="playlist_add"
            onClick={() => setBatchGroup(!batchGroup)}
          />
          <Button
            isPrimary
            icon="delete_sweep"
            onClick={() => setDeletePrompt(true)}
          />
          <Button isPrimary icon="history" onClick={() => setHistoryOpen(true)} />
        </div>
        <NavBarMore>
          <a
            href="#logout"
            className="navbar-item is-unselectable"
            onClick={withPrevent(logout)}
          >
            <Icon icon="exit_to_app" />
            <span>Wyloguj się</span>
          </a>
        </NavBarMore>
      </NavBar>

      <MainSection className="section">
        <div className="container">
          <div>
            {/* <update-notification /> */}
            <ShoppingList
              batchGroup={batchGroup}
              onBatchGroupClose={() => setBatchGroup(false)}
            />
          </div>
        </div>
      </MainSection>
      {historyOpen && <HistoryDialog onClose={() => setHistoryOpen(false)} />}
      {deletePrompt && (
        <ModalYesNo
          title="Usuń zakończone"
          onCancel={() => setDeletePrompt(false)}
          onOK={removeCompletedItems}
        >
          Czy na pewno usunąć wszystkie zakończone?
        </ModalYesNo>
      )}
      <UpdateNotification/>
    </div>
  )
}

export default App

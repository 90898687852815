import colors from "./colors"
import Icon from "./Icon"

export default function Button({ text, icon, isPrimary, isSmall, onClick }) {
  return (
    <button className={`button ${isPrimary ? colors.primary : ""} ${isSmall ? "is-small" : ""}`} onClick={onClick}>
      {icon && <Icon icon={icon} />}
      {text && <span>{text}</span>}
    </button>
  )
}

import { useEffect, useState } from "react"
import { withPrevent } from "./utils"
import styled from "styled-components"
import Button from "./Button"
import NavBar from "./NavBar"
import SubScreen from "./SubScreen"
import { addItems, getHistory } from "./database"

const HistoryContainer = styled.div.attrs(props => ({
  className: "container"
}))`
  &.container {
    max-width: 500px;
  }
`

const HistorySection = styled.div.attrs(props => ({
  className: "section"
}))`
  &.section {
    padding-top: 1rem;
  }

  .selected {
    background-color: #ccc;
  }

  .history-item {
    margin-bottom: 10px;
    user-select: none;
    cursor: pointer;
  }
`

const Filters = styled.div`
  padding-top: 1rem;
`

export default function HistoryDialog({ onClose }) {
  const [currentSort, setCurrentSort] = useState("most")
  const [lastMonth, setLastMonth] = useState(true)
  const [list, setList] = useState([])

  const numberOfItems = list.filter(x => x.selected).length

  async function addSelected() {
    const selectedItems = list.filter(x => x.selected)
    if (selectedItems.length) {
      await addItems(selectedItems)
      onClose()
    }
  }

  function toggle(item) {
    item.selected = !item.selected
    setList([...list])
  }

  useEffect(() => {
    ;(async function () {
      setList(await getHistory({
        sort: currentSort,
        lastMonth
      }))
    })()
  }, [currentSort, lastMonth])

  return (
    <SubScreen>
      <NavBar title="Historia" showBack onBack={onClose}>
        <div className="navbar-item">
          <a
            href="#addItems"
            className="button"
            onClick={withPrevent(addSelected)}
          >
            Dodaj ({numberOfItems})
          </a>
        </div>
      </NavBar>
      <HistoryContainer>
        <Filters>
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <div className="field has-addons">
                  <p className="control">
                    <Button
                      isSmall
                      isPrimary={currentSort === "alpha"}
                      onClick={() => setCurrentSort("alpha")}
                      text="A-Z"
                    />
                  </p>
                  <p className="control">
                    <Button
                      isSmall
                      isPrimary={currentSort === "date"}
                      onClick={() => setCurrentSort("date")}
                      text="Data"
                    />
                  </p>
                  <p className="control">
                    <Button
                      isSmall
                      isPrimary={currentSort === "most"}
                      onClick={() => setCurrentSort("most")}
                      text="Najczęściej"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Button
                  isSmall
                  isPrimary={lastMonth}
                  onClick={() => setLastMonth(!lastMonth)}
                  text="Ostatni miesiąc"
                />
              </div>
            </div>
          </div>
        </Filters>
        <HistorySection>
          {list.map(item => (
            <div
              key={item.id}
              className={`history-item is-unselectable ${
                item.selected ? "selected" : ""
              }`}
              onClick={() => toggle(item)}
            >
              {item.description}
            </div>
          ))}
        </HistorySection>
      </HistoryContainer>
    </SubScreen>
  )
}

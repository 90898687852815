import ListItem from "./ListItem";

export default function ListContainer({
  title,
  items,
  selectMode,
  onSelectItem,
  onEditItem,
  onToggleItem,
}) {
  return (
    <div className="box">
      {title && (
        <div className="title is-4 has-text-centered has-text-weight-bold">
          {title}
        </div>
      )}
      {items && items.map(item => (
        <ListItem
          key={item.id}
          item={item}
          onEditItem={onEditItem}
          onToggleItem={onToggleItem}
          selectMode={selectMode}
          onSelectItem={onSelectItem}
        />
      ))}
    </div>
  )
}

//import EE from "eventemitter3"
import { useEffect, useState } from "react"
import { io } from "socket.io-client"

//const listEmitter = new EE()
let socket

export async function getGroups() {
  const resp = await fetch("/api/groups")
  if (resp.ok) {
    const r = await resp.json()
    return r.groups
  } else {
    return []
  }
}

export async function updateItem(itemId, itemData) {
  const resp = await fetch("/api/list/update", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      item: {
        id: itemId,
        ...itemData
      }
    })
  })
  if (resp.ok) {
    //listEmitter.emit("listChange")
    return await resp.json()
  } else {
    return null
  }
}

export async function removeItem(itemId) {
  const resp = await fetch("/api/list/remove", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      item: {
        id: itemId
      }
    })
  })
  if (resp.ok) {
    //listEmitter.emit("listChange")
  }
  return resp.ok
}

export async function getHistory({ sort, lastMonth }) {
  const resp = await fetch("/api/history", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      sort,
      lastMonth
    })
  })
  if (resp.ok) {
    const history = await resp.json()
    history.forEach(h => (h.selected = false))
    return history
  } else {
    return []
  }
}

export async function addItem(item) {
  const resp = await fetch("/api/list/add", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      item: {
        description: item.description
      }
    })
  })
  if (resp.ok) {
    // listEmitter.emit("listChange")
    return await resp.json()
  } else {
    return null
  }
}

export async function addItems(items) {
  const resp = await fetch("/api/list/addItems", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      items: items.map(i => i.description)
    })
  })
  if (resp.ok) {
    // listEmitter.emit("listChange")
  }
  return resp.ok
}

export async function assignGroup(items, group) {
  const resp = await fetch("/api/list/assignGroup", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      itemIds: items.map(i => i.id),
      group
    })
  })
  if (resp.ok) {
    // listEmitter.emit("listChange")
  }
  return resp.ok
}

export async function toggleItem(item) {
  const resp = await updateItemDone(item.id, !item.done)
  if (resp.ok) {
    // listEmitter.emit("listChange")
  }
  return resp
}

async function updateItemDone(itemId, done) {
  const resp = await fetch("/api/list/update", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      item: {
        id: itemId,
        done: done
      }
    })
  })
  if (resp.ok) {
    // listEmitter.emit("listChange")
  }
  return resp.ok
}

export async function getGroupedItems() {
  const resp = await fetch("/api/lists")

  if (resp.status === 401) {
    window.location.href = "/logout"
  }

  return await resp.json()
}

export async function removeCompleted() {
  const resp = await fetch("/api/list/removeCompleted", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    }
  })
  if (resp.ok) {
    const r = await resp.json()
    // listEmitter.emit("listChange")
    return r.ids
  } else {
    return null
  }
}

export function useGroupedLists() {
  const [groupedLists, setGroupedLists] = useState({ default: [] })
  const [groupNames, setGroupNames] = useState([])
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    const refresh = async () => {
      try {
        const resp = await getGroupedItems()
        setGroupedLists(resp.groupedLists)
        setGroupNames(resp.groupNames)
      } catch {
        console.error("Błąd serwera")
        setServerError(true)
      }
    }

    refresh()
    if (!socket) {
      socket = io(process.env.REACT_APP_LIST_SOCKET, { path: "/listSocket", transports: ["websocket"] })
    }
    socket.on("listChange", refresh)

    return () => {
      socket.off("listChange", refresh)
    }
  }, [])

  return { groupedLists, groupNames, serverError }
}

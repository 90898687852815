import styled from "styled-components"

const FooterContainer = styled.div.attrs(props => ({
  className: "footer-container",
}))`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
`

const Container = styled.div.attrs(props => ({
  className: "container",
}))`
  max-width: 500px;
  padding: 16px 8px;
`

export default function FooterBox({ children }) {
  return (
    <FooterContainer>
      <Container className="container">{children}</Container>
    </FooterContainer>
  )
}

import { useEffect, useState } from "react"
import Button from "./Button"
import { assignGroup, getGroups } from "./database"
import FooterBox from "./FooterBox"

export default function GroupBox({ items, onClose }) {
  const [group, setGroup] = useState("")

  const [groups, setGroups] = useState([])
  const count = items.length

  useEffect(() => {
    (async function loadGroups() {
      setGroups(await getGroups())
    })()
  }, [])

  async function assignGroupToItems() {
    if (count > 0) {
      await assignGroup(items, group)
      onClose()
    }
  }

  return (
    <FooterBox>
      <div className="field has-addons">
        <div className="control is-expanded">
          <div className="select is-fullwidth">
            <select
              value={group}
              onChange={e => setGroup(e.target.value)}
              placeholder="Wybierz grupę"
            >
              <option value=""></option>
              {groups.map(g => (
                <option value={g} key={g}>
                  {g}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="control">
          <Button
            text={`Przypisz grupę (${count})`}
            onClick={assignGroupToItems}
            isPrimary
          />
        </div>
      </div>
    </FooterBox>
  )
}

import { useState } from "react"
import styled from "styled-components"
import Icon from "./Icon"

const ListItemContainer = styled.div.attrs(props => ({
  className: "media is-unselectable",
}))`
  &.media + &.media {
    margin-top: 0;
    border-top: none;
  }
`

export default function ListItem({
  item,
  selectMode,
  onSelectItem,
  onEditItem,
  onToggleItem,
}) {
  const [selected, setSelected] = useState(false)

  function select() {
    if (selectMode) {
      setSelected(!selected)
      onSelectItem(item, !selected)
    }
  }

  return (
    <ListItemContainer>
      <div className="media-left">
        {selectMode ? (
          <Icon />
        ) : (
          <Icon
            icon={item.done ? "check_box" : "check_box_outline_blank"}
            onClick={() => onToggleItem(item)}
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>
      <div
        className="media-content"
        style={{ cursor: selectMode ? "pointer" : "inherit" }}
        onClick={select}
      >
        <span
          style={{ textDecoration: item.done ? "line-through" : "none" }}
          onDoubleClick={() => onEditItem(item)}
        >
          {item.description}
        </span>
      </div>
      {selectMode && (
        <div className="media-right">{selected && <Icon icon="check" />}</div>
      )}
    </ListItemContainer>
  )
}

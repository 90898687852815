import styled from "styled-components"

const SubScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 2000;
  overflow-y: auto;
`

export default SubScreen

import { useState } from "react"
import styled from "styled-components"
import Button from "./Button"
import { removeItem, updateItem } from "./database"
import InputField from "./InputField"
import NavBar from "./NavBar"
import SubScreen from "./SubScreen"

const EditContainer = styled.div.attrs(props => ({
  className: "container section",
}))`
  &.container.section {
    max-width: 500px;
  }
`

export default function EditItem({ item, onClose }) {
  const [description, setDescription] = useState(item.description)
  const [group, setGroup] = useState(item.group || "")

  const groups = ["ikea", "hahah", "asdf"]

  function save() {
    updateItem(item.id, {
      description,
      group,
    })
    onClose()
  }

  function remove() {
    removeItem(item.id)
    onClose()
  }

  return (
    <SubScreen>
      <NavBar title="Edycja" showBack onBack={save}>
        <div className="navbar-item">
          <Button text="Usuń" icon="delete_forever" onClick={remove} />{" "}
        </div>
      </NavBar>
      <EditContainer>
        <InputField
          label="Opis"
          value={description}
          onChange={d => setDescription(d)}
        />
        <InputField
          label="Grupa"
          value={group}
          onChange={g => setGroup(g)}
          list={groups}
        />
      </EditContainer>
    </SubScreen>
  )
}

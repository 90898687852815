import { useState } from "react"
import Icon from "./Icon"
import { withPrevent } from "./utils"

export default function NavBarMore({ children }) {
  const [moreOpen, setMoreOpen] = useState(false)

  return (
    <div className={`navbar-item has-dropdown ${moreOpen ? "is-active" : ""}`}>
      <a
        href="#more"
        className="navbar-link is-arrowless is-unselectable"
        onClick={withPrevent(() => setMoreOpen(!moreOpen))}
      >
        <Icon icon="more_vert" />
      </a>
      <div className="navbar-dropdown is-right">{children}</div>
    </div>
  )
}

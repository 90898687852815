import FooterBox from "./FooterBox"
import Button from "./Button"
import { useEffect, useRef, useState } from "react"
import { addItem } from "./database"

export default function AddBox() {
  const [description, setDescription] = useState("")
  const input = useRef()

  function addOnEnter(e) {
    if (e.key === "Enter") {
      add()
    }
  }

  function add() {
    addItem({
      description
    })
    setDescription("")
  }

  useEffect(() => {
    input.current.focus()
  }, [])

  return (
    <FooterBox>
      <div className="field has-addons">
        <div className="control is-expanded">
          <input
            value={description}
            onChange={e => setDescription(e.target.value)}
            autoCapitalize="off"
            onKeyDown={addOnEnter}
            className="input"
            type="text"
            ref={input}
            placeholder="Nowy produkt"
          />
        </div>
        <div className="control">
          <Button text="Dodaj" onClick={add} isPrimary />
        </div>
      </div>
    </FooterBox>
  )
}
